export const routeBenefits = ref<string[] | null>(null)

export const getGivebackSummaryV2 = async (zip_code: string) => {
  let url =
    import.meta.env.VITE_WEBSITE_API_URL +
    `/benefits/v2/summary?zip_code=${zip_code}`
  const { data } = await axios.get(url)

  return data
}
